




















import { Component, Vue } from 'vue-property-decorator'

import LandingTitle from './LandingTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'

@Component({
  name: 'LandingVideo',
  components: {
    LandingTitle,
    BaseButton,
  },
})
export default class LandingVideo extends Vue {
  openVideoPage(): void {
    window.open('https://www.youtube.com/embed/xdLYv93c9gc', '_blank')
  }
}
